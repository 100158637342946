import api from "!../node_modules/style-loader/dist/runtime/injectStylesIntoStyleTag.js";
            import content from "!!../node_modules/css-loader/dist/cjs.js!../node_modules/postcss-loader/dist/cjs.js??ref--5-2!./videostyle.css";

var options = {"injectType":"singletonStyleTag","attributes":{"data-cke":true}};

options.insert = "head";
options.singleton = true;

var update = api(content, options);



export default content.locals || {};